import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ICategory } from "../../types/content"
import { IChatMessage } from "../../types/chat"

type IState = {
  serviceList: ICategory[]
  serviceListLoading: boolean
  countOrdersWithoutReview: number
  countUnreadMessages: number
  countActiveCoordinations: number
  isHaveCoordinations: boolean
  allMessage: IChatMessage[]
  soloServiceIsOpened: boolean
}

const initialState: IState = {
  serviceList: [],
  serviceListLoading: true,
  countOrdersWithoutReview: 0,
  countUnreadMessages: 0,
  countActiveCoordinations: 0,
  isHaveCoordinations: false,
  allMessage: [],
  soloServiceIsOpened: false,
}

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    changeServiceListLoading: (state: any, action: PayloadAction<boolean>) => {
      state.serviceListLoading = action.payload
    },
    updateServiceList: (state: any, action: PayloadAction<ICategory[]>) => {
      state.serviceList = action.payload
      state.serviceListLoading = false
    },
    updateCountOrdersWithoutReview: (state: IState, action: PayloadAction<number>) => {
      state.countOrdersWithoutReview = action?.payload
    },
    increaseCountOrdersWithoutReview: (state: IState) => {
      state.countOrdersWithoutReview += 1
    },
    decreaseCountOrdersWithoutReview: (state: IState) => {
      if (state.countOrdersWithoutReview === 0) return
      state.countOrdersWithoutReview -= 1
    },
    updateCountUnreadMessages: (state: IState, action: PayloadAction<number>) => {
      state.countUnreadMessages = action?.payload
    },
    decreaseCountUnreadMessages: (state: IState) => {
      state.countUnreadMessages -= 1
    },
    updateCountActiveCoordinations: (state: IState, action: PayloadAction<number>) => {
      state.countActiveCoordinations = action?.payload
    },
    setIsHaveCoordinations: (state: IState, action: PayloadAction<boolean>) => {
      state.isHaveCoordinations = action?.payload
    },
    setAllMessage: (state: any, action: PayloadAction<any>) => {
      state.allMessage = action.payload
    },
    addAllMessage: (state: IState, action: PayloadAction<IChatMessage>) => {
      state.allMessage = [...state.allMessage.filter((f) => f.id !== action.payload.id), action.payload]
    },
    updateReadMessages: (state: any, action: PayloadAction<any>) => {
      state.allMessage = [
        ...state.allMessage.map((f: any) => (action?.payload?.includes(f.id) ? { ...f, is_readed: true } : f)),
      ]
    },
    setSoloServiceIsOpened: (state: any, action: PayloadAction<boolean>) => {
      state.soloServiceIsOpened = action.payload
    },
  },
})

export const {
  changeServiceListLoading,
  updateServiceList,
  updateCountOrdersWithoutReview,
  updateCountUnreadMessages,
  decreaseCountUnreadMessages,
  decreaseCountOrdersWithoutReview,
  increaseCountOrdersWithoutReview,
  updateCountActiveCoordinations,
  setIsHaveCoordinations,
  setAllMessage,
  addAllMessage,
  updateReadMessages,
  setSoloServiceIsOpened,
} = mainSlice.actions
export const countOrdersWithoutReview = (state: any) => state[mainSlice.name].countOrdersWithoutReview
export const countUnreadMessages = (state: any) => state[mainSlice.name].countUnreadMessages
export const selectAllMessage = (state: any) => state[mainSlice.name].allMessage
export const isHaveCoordinations = (state: any) => state[mainSlice.name].isHaveCoordinations
export const countActiveCoordinations = (state: any) => state[mainSlice.name].countActiveCoordinations
