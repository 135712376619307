export const ArrowCircleBottom = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5618 14.676C5.94163 16.0935 6.7786 17.3461 7.94289 18.2395C9.10719 19.1329 10.5337 19.6172 12.0013 19.6172C13.4689 19.6172 14.8954 19.1329 16.0597 18.2395C17.224 17.3461 18.061 16.0935 18.4408 14.676"
        stroke="#668899"
        strokeWidth="1.90476"
      />
      <path
        d="M12.0002 12.9512L11.4052 13.6949L12.0002 14.1708L12.5951 13.6949L12.0002 12.9512ZM12.9526 4.37974C12.9526 3.85376 12.5262 3.42736 12.0002 3.42736C11.4742 3.42736 11.0478 3.85376 11.0478 4.37974L12.9526 4.37974ZM6.64333 9.88533L11.4052 13.6949L12.5951 12.2075L7.83323 8.39796L6.64333 9.88533ZM12.5951 13.6949L17.357 9.88533L16.1671 8.39796L11.4052 12.2075L12.5951 13.6949ZM12.9526 12.9512L12.9526 4.37974L11.0478 4.37974L11.0478 12.9512L12.9526 12.9512Z"
        fill="#668899"
      />
    </svg>
  )
}
