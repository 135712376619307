import { FC, Fragment, ReactNode, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import clsx from "clsx"
import styles from "./MobileMoreModal.module.scss"
import {
  getMobileMenuIsHidden,
  getOpenIsMoreModal,
  setMobileMenuIsHidden,
  setOpenIsMoreModal,
} from "../../../redux/slice/isMoreModal"
import { useAppSelector } from "../../../hooks"
import Header from "../../Header"
import Search from "../../Search/Search"
import Footer from "../../Footer"
import moment from "moment"
import { langWithCurrentMomentCode } from "../../../utils/helpers"
import { authModalOpen } from "../../../redux/slice/authModal"
import { StarIconBorder } from "../../../icons/StarIconBorder"
import { UserIcon } from "../../../icons/UserIcon"
import { languageModalOpen } from "../../../redux/slice/languageModal"
import { DEFAULT_LANGUAGE, LANG_LIST } from "../../../utils/constants"
import { ExitIcon } from "../../../icons/ExitIcon"
import LocalizedLink from "../../../hoc/LocalizedLink"
import { useScrollBlock } from "../../../hooks/useScrollBlock"
import { useTranslation } from "react-i18next"
import { countOrdersWithoutReview } from "../../../redux/slice/main"
import { useLogout } from "../../../hooks/useLogout"
import { getLocale } from "../../../utils/getLocale"
import { selectIsAllowUsersInLK } from "../../../redux/slice/auth"
import { DesktopLight } from "../../../icons/DesktopLight"

interface listItem {
  name: string
  link?: string
  txt: string
  description: string
  icon?: ReactNode
  func?: () => void
  classIcon?: string
  bg?: string
  quantityIndicator?: number
}

const MobileMoreModal: FC = () => {
  const [searchVal, setSearchVal] = useState<string | number>("")
  const [isExitBtnDisabled, setExitBtnDisabled] = useState<undefined | true>(undefined)
  const currentLang = getLocale()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const mobileMoreModalIsOpen = useAppSelector(getOpenIsMoreModal)
  const mobileMenuIsHidden = useAppSelector(getMobileMenuIsHidden)
  const isAllowUsersInLK = useAppSelector(selectIsAllowUsersInLK)
  const { allowScroll } = useScrollBlock()
  const logout = useLogout()

  const quantityOrdersWithoutReview = useAppSelector(countOrdersWithoutReview)

  const listMenu: listItem[] = useMemo(() => {
    const curLangList = LANG_LIST.find((lang) => lang.value === currentLang)

    return [
      {
        name: "reviews",
        link: "/reviews",
        txt: "reviews",
        description: "yourOpinionImportantUs",
        icon: <StarIconBorder />,
        func: () => {
          dispatch(setOpenIsMoreModal(false))
        },
        bg: "yellow",
        quantityIndicator: quantityOrdersWithoutReview,
      },
      {
        name: "employees",
        link: "/employees",
        txt: "employees",
        description: "employeeBase",
        func: () => {
          dispatch(setOpenIsMoreModal(false))
        },
        icon: <UserIcon />,
      },
      {
        name: "equipment",
        link: "/equipment",
        txt: "equipment",
        description: "issuedEquipment",
        func: () => {
          dispatch(setOpenIsMoreModal(false))
        },
        icon: <DesktopLight />,
      },
      // {
      //   name: "language",
      //   txt: curLangList?.text || "Русский",
      //   description: "interfaceLanguage",
      //   icon: <img src={`/img/lang/${curLangList?.value ?? "ru"}.png`} alt="" />,
      //   func: () => {
      //     dispatch(languageModalOpen(true))
      //   },
      //   classIcon: "lang-icon",
      // },
      {
        name: "exit",
        txt: "logout",
        description: "fromPersonalAccount",
        icon: <ExitIcon />,
        func: () => {
          setExitBtnDisabled(true)
          logout()
        },
        classIcon: "exit-icon",
      },
    ]
  }, [currentLang, quantityOrdersWithoutReview])

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
    } else {
      moment.locale(DEFAULT_LANGUAGE)
    }

    return () => {
      allowScroll(true)
    }
  }, [currentLang])

  const setOpenModal = () => {
    dispatch(setOpenIsMoreModal(!mobileMoreModalIsOpen))
  }

  const MenuItemContent = ({ item }: { item: listItem }) => {
    return (
      <>
        <div
          className={clsx(
            styles["item__icon"],
            item?.bg && styles["item__icon--" + item.bg],
            item?.classIcon && styles[`${item.classIcon}`],
          )}
        >
          {item.icon}
        </div>
        <div className={clsx(styles["item__content"])}>
          <div className={clsx(styles["item__content__txt"])}>{item.name === "language" ? item.txt : t(item.txt)}</div>
          <div className={clsx(styles["item__content__desc"])}>{t(item.description)}</div>
        </div>
        {item.quantityIndicator ? (
          <span
            className={clsx(styles["quantity-indicator"], {
              [styles["quantity-indicator__bigger"]]: item.quantityIndicator > 99,
            })}
          >
            {item.quantityIndicator > 99 ? "99+" : item.quantityIndicator}
          </span>
        ) : null}
      </>
    )
  }

  return (
    <ModalPortal
      isOpen={mobileMoreModalIsOpen}
      setIsOpen={setOpenModal}
      className={clsx(styles.modal, styles.MobileMoreModal, mobileMenuIsHidden && styles["hidden-mobile-menu"])}
      isCloseBtn={false}
      name="MobileMoreModal"
    >
      <div className={styles.MobileMoreModalContent}>
        <Header />
        <Search searchVal={searchVal} setSearchVal={setSearchVal} />
        <div className={clsx(styles["content-container"])}>
          <h2>{t("more")}</h2>
          <div className={clsx(styles["content-list"])}>
            {listMenu.map((item, i) => {
              if (item.name === "employees" && !isAllowUsersInLK) return null
              return (
                <Fragment key={i}>
                  {item.link ? (
                    <LocalizedLink
                      to={item.link}
                      className={clsx(styles["item"])}
                      onClick={() => {
                        if (item.func) item.func()
                      }}
                    >
                      <MenuItemContent item={item} />
                    </LocalizedLink>
                  ) : (
                    <div
                      className={clsx(styles["item"])}
                      onClick={() => {
                        if (item.name === "exit" && isExitBtnDisabled) {
                          return
                        }
                        if (item.func) item.func()
                      }}
                    >
                      <MenuItemContent item={item} />
                    </div>
                  )}
                </Fragment>
              )
            })}
          </div>
        </div>
        <Footer />
      </div>
    </ModalPortal>
  )
}

export default MobileMoreModal
