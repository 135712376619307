export const EndFilledIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM4.5 4C4.22386 4 4 4.22386 4 4.5V7.5C4 7.77614 4.22386 8 4.5 8H7.5C7.77614 8 8 7.77614 8 7.5V4.5C8 4.22386 7.77614 4 7.5 4H4.5Z"
        fill="#F2200D"
      />
    </svg>
  )
}
