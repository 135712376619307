import React, { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import moment from "moment"
import { useTranslation } from "react-i18next"
import TextareaAutosize from "react-textarea-autosize"
import { NumericFormat } from "react-number-format"
import FileDropzone from "../../Assets/FileDropzone/FileDropzone"
import OrderModalBlock from "./OrderModalBlock"
import OrderModalPhotoBlock from "./OrderModalPhotoBlock"
import CheckBoxRow from "../../Assets/CheckBoxRow/CheckBoxRow"
import RadioRow from "../../Assets/RadioRow/RadioRow"
import { getApiUrl } from "../../../utils/getApiUrl"
import useWindowSize from "../../../hooks/useWindowSize"
import PreviewFile from "../../Assets/PreviewFile/PreviewFile"
import { TOrderOption } from "../../../types/orderTypes"
import OrderServiceReference from "../../OrderService/OrderServiceReference/OrderServiceReference"
import styles from "./OrderModal.module.scss"
import { QRMobIcon } from "../../../icons/QRMobIcon"
import QrScanModal from "../QrScanModal/QrScanModal"
import IconBtn from "../../Assets/IconBtn/IconBtn"
import QrScanner from "qr-scanner"

interface IOrderModalCommonBlock {
  el: TOrderOption
  setError?: (val: boolean) => void
  isFirstQrDate?: number | null
}

const OrderModalCommonBlock: React.FC<IOrderModalCommonBlock> = ({ el, setError, isFirstQrDate }) => {
  const { isDesktop } = useWindowSize()
  const { values, setFieldValue, errors, setFieldTouched, touched } = useFormikContext<{ [key: string]: any }>()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const [element] = useState(el)
  const [isQrScanModal, setQrScanModal] = useState<boolean>(false)
  const [isCamera, setCamera] = useState<boolean>(false)

  useEffect(() => {
    if (isFirstQrDate && !values[element.id]) setFieldValue(`${element.id}`, isFirstQrDate)
  })

  useEffect(() => {
    QrScanner.hasCamera().then((res) => setCamera(res))
  }, [])

  const checkOnChecking = (id: string, field_id: string) => {
    const elSelected = values?.[field_id]?.includes(id)
    if (elSelected) {
      setFieldValue(field_id, values[field_id]?.filter((el: any) => el !== id) ?? [])
    } else {
      setFieldValue(field_id, [...values[field_id], id])
    }
  }

  const addInfoForNumberInput = (el: any) => {
    let text = ""
    if (el.min || el.max) text += "."
    if (el.min) text += ` ${t("fromu")} ${el.min}`
    if (el.max) text += ` ${!el.min ? t("tou") : t("to")} ${el.max}`
    return text
  }

  useEffect(() => {
    setTimeout(() => {
      if (el.type === "information") {
        setFieldValue(`${el.id}`, ["information", 1])
      }
    }, 100)
  }, [])

  useEffect(() => {
    return () => {
      if (element.type === "text") setFieldValue(`${element.id}`, "")
      if (element.type === "boolean") setFieldValue(`${element.id}`, null)
      if (element.type === "checkbox") setFieldValue(`${element.id}`, null)
      if (element.type === "number") setFieldValue(`${element.id}`, null)
      if (element.type === "datetime") setFieldValue(`${element.id}`, undefined)
      if (element.type === "list") setFieldValue(`${element.id}`, [])
      if (element.type === "photo") setFieldValue(`${element.id}`, [])
      if (element.type === "attachment") setFieldValue(`${element.id}`, [])
      if (element.type === "information") setFieldValue(`${element.id}`, ["information", 0])
      if (element.type === "qr") setFieldValue(`${element.id}`, undefined)
    }
  }, [])

  {
    switch (element.type) {
      case "directory":
        return (
          <OrderServiceReference
            title={element.name}
            isRequired={element?.required}
            onSubmit={(ids) => {
              void setFieldTouched(`directory-${element.id}`)
              void setFieldValue(`directory-${element.id}`, ids)
            }}
            onClear={(ids) => {
              void setFieldValue(`directory-${element.id}`, ids)
            }}
            type={element.directory_type}
            error={Boolean(touched[`directory-${element.id}`] && errors[`directory-${element.id}`])}
            isMultiple={element?.multiple}
          />
        )
      case "information":
        return (
          <OrderModalBlock title={``}>
            <div className={`${styles.orderModal__info}`}>
              <div className={`${styles.orderModal__blockTitle}`}>{element.name}</div>
              <div
                className={`${styles.orderModal__infoText}`}
                dangerouslySetInnerHTML={{ __html: element?.description?.trim() ?? "" }}
              />
              {!!element?.photos?.length && (
                <div className={styles.photoBlock}>
                  {element?.photos?.map((el: string, kk: number) => (
                    <div className={styles.photoBlock__preview} key={kk}>
                      <img src={getApiUrl() + "/public/photo/" + el} alt="" />
                    </div>
                  ))}
                </div>
              )}
              {!!element?.attachments_info?.length && (
                <div className={`${styles.orderModal__files}`}>
                  {element?.attachments_info?.map((el) => <PreviewFile key={el.id} {...el} />)}
                </div>
              )}
            </div>
          </OrderModalBlock>
        )
      case "photo":
        return (
          <OrderModalPhotoBlock
            el={element}
            values={values}
            setFieldValue={setFieldValue}
            isRequired={element?.required}
            error={!!errors?.[element?.id] && !!touched?.[element?.id]}
          />
        )
      case "text":
        return (
          <OrderModalBlock title={`${element?.name}`} isRequired={element?.required}>
            <TextareaAutosize
              minRows={1}
              maxRows={100}
              placeholder={t("enterComment")}
              className={`textarea ${!!errors?.[element?.id] && !!touched?.[element?.id] ? "error" : ""}`}
              onChange={(e) => setFieldValue(`${element.id}`, e.target.value)}
              onBlur={() => setFieldTouched(element.id, true)}
            />
          </OrderModalBlock>
        )
      case "list":
        return (
          <OrderModalBlock
            title={`${element?.name}`}
            isRequired={element?.required}
            error={!!errors?.[element?.id] && !!touched?.[element?.id]}
          >
            {element?.items?.map((item) =>
              el?.multiple ? (
                <CheckBoxRow
                  key={item.id}
                  name={item.id}
                  title={item.name}
                  onChange={() => checkOnChecking(item.id, el.id)}
                  value={item.id || ""}
                  checked={values[el.id]?.find((val: string) => val === item.id) || false}
                />
              ) : (
                <RadioRow
                  key={item.id}
                  name={item.id}
                  title={item.name}
                  onChange={() => {
                    setFieldValue(`${el.id}`, [item.id])
                  }}
                  value={item.id || ""}
                  checked={values[el.id]?.find((val: string) => val === item.id) || false}
                />
              ),
            )}
          </OrderModalBlock>
        )
      case "datetime":
        return (
          <OrderModalBlock title={`${element?.name}`} isRequired={element?.required}>
            <div className={styles.orderModal__picker}>
              <input
                type={element.only_date ? "date" : "datetime-local"}
                placeholder={!isDesktop ? t("enterData") : undefined}
                onChange={(e: any) => {
                  e.currentTarget.classList.add(styles.has_value)
                  if (e.target.value.trim().length) {
                    element.only_date
                      ? setFieldValue(`${element.id}`, moment(e.target.value).format("YYYY-MM-DD"))
                      : setFieldValue(`${element.id}`, moment(e.target.value).format("x"))
                  } else {
                    setFieldValue(`${element.id}`, undefined)
                    e.currentTarget.classList.remove(styles.has_value)
                  }
                }}
                value={
                  element.only_date
                    ? values[element.id]
                      ? moment(values[element.id]).format("YYYY-MM-DD")
                      : undefined
                    : values[element.id]
                    ? moment(Number(values[element.id])).format("YYYY-MM-DD HH:mm")
                    : undefined
                }
                min={moment().format("YYYY-MM-DD")}
                className={`input ${!!errors?.[element?.id] && !!touched?.[element?.id] ? "error" : ""}`}
              />
              <img src="/img/picker.svg" alt="" />
            </div>
          </OrderModalBlock>
        )
      case "number":
        return (
          <OrderModalBlock title={`${element?.name}${addInfoForNumberInput(element)}`} isRequired={element?.required}>
            <NumericFormat
              className={`input ${!!errors?.[element?.id] && !!touched?.[element?.id] ? "error" : ""}`}
              value={values[element.id] || ""}
              allowNegative={!element.unsigned}
              autoComplete="false"
              onChange={(e) => {
                setFieldValue(`${element.id}`, e.target.value)
                setError && setError(false)
                e.target.classList.remove("error")
              }}
              placeholder={t("enterNum")}
              onBlur={(e) => {
                if (e.target.value && element.min && Number(e.target.value) < Number(element.min)) {
                  setError && setError(true)
                  e.target.classList.add("error")
                  return
                }
                if (e.target.value && element.max && Number(e.target.value) > Number(element.max)) {
                  setError && setError(true)
                  e.target.classList.add("error")
                  return
                }
                if (!element?.required) {
                  setError && setError(false)
                  e.target.classList.remove("error")
                }
                setFieldTouched(element.id, true)
              }}
              decimalScale={element.integer ? 0 : 2}
            />
          </OrderModalBlock>
        )
      case "checkbox":
        return (
          <OrderModalBlock title={``} isRequired={element?.required}>
            <CheckBoxRow
              name={element.id}
              title={element.name}
              onChange={() => setFieldValue(`${element.id}`, !values[element.id])}
              value={values[element.id]}
              checked={values[element.id] || false}
              error={!!errors?.[element?.id] && !!touched?.[element?.id]}
              className={styles.checkboxAlone}
            />
          </OrderModalBlock>
        )
      case "boolean":
        return (
          <OrderModalBlock
            title={`${element.name}`}
            isRequired={element?.required}
            error={!!errors?.[element?.id] && !!touched?.[element?.id]}
          >
            <RadioRow
              name={element.name}
              title={t("yes")}
              // onChange={() => {
              //   setFieldValue(`${element.id}`, true)
              // }}
              onClick={() => {
                const needValue = values[element.id] === true ? null : true
                setFieldValue(`${element.id}`, needValue)
              }}
              value={true}
              checked={values[element.id] === true}
            />
            <RadioRow
              name={element.name}
              title={t("no")}
              // onChange={() => setFieldValue(`${element.id}`, false)}
              onClick={() => {
                const needValue = values[element.id] === false ? null : false
                setFieldValue(`${element.id}`, needValue)
              }}
              value={false}
              checked={values[element.id] === false}
            />
          </OrderModalBlock>
        )
      case "attachment":
        return (
          <OrderModalBlock title={`${element.name}`} isRequired={element?.required}>
            <div className={`${styles.orderModal__files}`}>
              <FileDropzone
                name={element.id}
                setFieldValue={setFieldValue}
                values={values}
                error={!!errors?.[element?.id] && !!touched?.[element?.id]}
              />
            </div>
          </OrderModalBlock>
        )

      case "qr":
        return (
          <OrderModalBlock title={`${element.name}`} isRequired={element?.required}>
            {values[element.id] ? (
              <div className={styles.QRInput}>
                <input
                  disabled
                  value={values[element.id] ?? undefined}
                  className={`input ${!!errors?.[element?.id] && !!touched?.[element?.id] ? "error" : ""}`}
                />
                <IconBtn
                  icon={<QRMobIcon />}
                  className={styles.QRInput__icon}
                  onClick={() => {
                    if (isFirstQrDate) return
                    if (!isCamera) return
                    setQrScanModal(true)
                  }}
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  if (!isCamera) return
                  setQrScanModal(true)
                }}
                className={styles.qrBlock}
              >
                <QRMobIcon />
                {t("qr.scanQr")}
              </div>
            )}
            {isQrScanModal && (
              <QrScanModal
                open={isQrScanModal}
                setOpen={setQrScanModal}
                afterScaningFunc={(val: string) => {
                  setFieldValue(`${element.id}`, val)
                  setQrScanModal(false)
                }}
              />
            )}
          </OrderModalBlock>
        )

      default:
        return <></>
    }
  }
}

export default OrderModalCommonBlock
