import { FC, ReactNode, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { selectActiveOrders, selectIsAllowUsersInLK } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"
import LocalizedLink from "../../hoc/LocalizedLink"
import { useLazyGetCountUnreadMessagesQuery, useLazyGetQuantityOrderWithoutReviewQuery } from "../../redux/api/content"
import { useAppDispatch } from "../../hooks"
import {
  updateCountOrdersWithoutReview,
  countOrdersWithoutReview,
  countUnreadMessages,
  updateCountUnreadMessages,
  isHaveCoordinations,
  countActiveCoordinations,
  updateCountActiveCoordinations,
} from "../../redux/slice/main"
import clsx from "clsx"
import { WorkListIcon } from "../../icons/WorkListIcon"
import { ArchiveListIcon } from "../../icons/ArchiveListIcon"
import { SendIcon } from "../../icons/SendIcon"
import { StarIconBorder } from "../../icons/StarIconBorder"
import { DoubleCheckIcon } from "../../icons/DoubleCheckIcon"
import { BookOpenIconStroke } from "../../icons/BookOpenIconStroke"
import { UserIcon } from "../../icons/UserIcon"
import { QRIcon } from "../../icons/QRIcon"
import useWindowSize from "../../hooks/useWindowSize"
import OrdersLineLoader from "./OrdersLineLoader"
import MessagesModal from "../Modals/MessagesModal/MessagesModal"
import styles from "./OrdersLine.module.scss"
import { declensionNumber } from "../../utils/helpers"
import { getMessagesModal, setMessagesModalIsOpen } from "../../redux/slice/modals"
import QrScanModal from "../Modals/QrScanModal/QrScanModal"
import QrScanner from "qr-scanner"
import { useLazyGetCountActiveApprovalsQuery } from "../../redux/api/coordinations"
import { getLocale } from "../../utils/getLocale"
import { DesktopLight } from "../../icons/DesktopLight"

interface Props {
  isLoading?: boolean
}

type listItem = {
  name: string
  icon: ReactNode
  link?: string
  txt?: string
  txtTrans?: string
  txtDesk?: string
  isSeparator?: boolean
  description?: string
  descriptionTrans?: string
  bg?: "red" | "yellow"
}

const list: listItem[] = [
  {
    name: "orders",
    link: "/orders",
    txtTrans: "requests",
    txtDesk: "заявки",
    descriptionTrans: "actRequests",
    icon: <WorkListIcon />,
  },
  {
    name: "history",
    link: "/history",
    txtTrans: "history",
    descriptionTrans: "completedRequests",
    icon: <ArchiveListIcon />,
  },
  {
    name: "separator1",
    isSeparator: true,
    icon: <></>,
  },
  {
    name: "messages",
    txtTrans: "messages",
    descriptionTrans: "accordingRequests",
    icon: <SendIcon />,
    bg: "red",
  },
  {
    name: "reviews",
    link: "/reviews",
    txtTrans: "reviews",
    descriptionTrans: "yourOpinionImportantUs",
    icon: <StarIconBorder />,
    bg: "yellow",
  },
  {
    name: "coordinations",
    link: "/coordinations",
    txtTrans: "coordinations",
    description: "Требуется от вас",
    icon: <DoubleCheckIcon />,
  },
  {
    name: "employees",
    link: "/employees",
    txtTrans: "employees",
    descriptionTrans: "employeeDatabase",
    icon: <UserIcon />,
  },
  {
    name: "knowledge",
    link: "/articles",
    txtTrans: "knowledgeBase",
    descriptionTrans: "usefulArticles",
    icon: <BookOpenIconStroke />,
  },
  {
    name: "equipment",
    link: "/equipment",
    txtTrans: "equipment",
    descriptionTrans: "issuedEquipment",
    icon: <DesktopLight />,
  },
  {
    name: "qr",
    txtTrans: "qr.scanQr",
    descriptionTrans: "qr.forQuickTransition",
    icon: <QRIcon />,
  },
]

const OrdersLine: FC<Props> = ({ isLoading }) => {
  const currentLang = getLocale()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const activeOrders = useAppSelector(selectActiveOrders)

  const dispatch = useAppDispatch()
  const quantityOrdersWithoutReview = useAppSelector(countOrdersWithoutReview)
  const quantityCountUnreadMessages = useAppSelector(countUnreadMessages)
  const quantityCountCoordinations = useAppSelector(countActiveCoordinations)
  const isCoordinationsLine = useAppSelector(isHaveCoordinations)
  const isAllowUsersInLK = useAppSelector(selectIsAllowUsersInLK)
  const { isDesktop } = useWindowSize()
  const [isQrScanModal, setQrScanModal] = useState<boolean>(false)
  const [isCamera, setCamera] = useState<boolean>(false)

  const [getQuantityOrderWithoutReview] = useLazyGetQuantityOrderWithoutReviewQuery()
  const [getCountUnreadMessages] = useLazyGetCountUnreadMessagesQuery()
  const [getCountActiveApprovals] = useLazyGetCountActiveApprovalsQuery()

  useEffect(() => {
    if (!isLoading) {
      getQuantityOrderWithoutReview().then((res) => {
        dispatch(updateCountOrdersWithoutReview(res?.data?.count || 0))
      })
      getCountUnreadMessages().then((res) => {
        dispatch(updateCountUnreadMessages(res?.data?.count || 0))
      })
      getCountActiveApprovals().then((res) => {
        dispatch(updateCountActiveCoordinations(res.data?.iCount || 0))
      })
    }
  }, [isLoading])

  useEffect(() => {
    QrScanner.hasCamera().then((res) => setCamera(res))
  }, [])

  return isLoading ? (
    isDesktop ? (
      <OrdersLineLoader />
    ) : (
      <div className={styles.ordersLine}>
        <div className={clsx(styles.item, styles["item--is-active"], "selectBack")} />
        <div className={clsx(styles.item, "selectBack")} />
      </div>
    )
  ) : (
    <>
      <div className={styles.ordersLine}>
        {list.map((item) => {
          if (item.name === "coordinations" && !isCoordinationsLine) return
          if (item.name === "employees" && !isAllowUsersInLK) return null
          if (item?.isSeparator) {
            return <hr key={item.name} className={styles.separator} />
          }
          const activeOrd =
            item.name === "orders" && activeOrders ? (
              <span className={styles.item__activeCount}>{activeOrders}</span>
            ) : null
          const ordersNoRev =
            item.name === "messages"
              ? quantityCountUnreadMessages
              : item.name === "reviews"
              ? quantityOrdersWithoutReview
              : item.name === "coordinations"
              ? quantityCountCoordinations
              : null
          if (item?.link) {
            return (
              <LocalizedLink
                key={item.name}
                to={item.link}
                className={clsx(styles.item, item.name === "orders" && styles["item--is-active"])}
              >
                <span className={clsx(styles.item__icon, item?.bg && styles["item__icon--" + item.bg])}>
                  {item.icon}
                </span>
                <span className={styles["item__txt-container"]}>
                  <span className={styles.item__txtWrap}>
                    {activeOrd}
                    {item.name === "orders" && !activeOrders && item.txtTrans ? (
                      <span className={styles.item__activeCount}>{t(item.txtTrans)}</span>
                    ) : (
                      item?.txtTrans && (
                        <span className={clsx(item?.txtDesk && styles["item__txt--mob"])}>
                          {currentLang === "ru" && item.name === "orders"
                            ? declensionNumber(Number(activeOrders), ["заявка", "заявки", "заявок"])
                            : t(item.txtTrans)}
                        </span>
                      )
                    )}

                    {item?.txt && <span className={clsx(item?.txtDesk && styles["item__txt--mob"])}>{item.txt}</span>}
                    {/* {item?.txtDesk && <span className={clsx(styles["item__txt--desktop"])}>{item.txtDesk}</span>} */}
                  </span>

                  {item.name === "orders" && !activeOrders ? (
                    <span className={styles.item__txtDescr}>{t("noActiveOrders")}</span>
                  ) : item?.description || item?.descriptionTrans ? (
                    <span className={styles.item__txtDescr}>
                      {item?.descriptionTrans ? t(item.descriptionTrans) : item.description}
                    </span>
                  ) : null}
                </span>
                {ordersNoRev && ordersNoRev > 0 ? (
                  <span className={`${styles["item__no-rev"]}`}>{ordersNoRev > 99 ? "99+" : ordersNoRev}</span>
                ) : null}
              </LocalizedLink>
            )
          } else {
            if (item.name === "qr" && !isCamera) return null
            return (
              <button
                key={item.name}
                className={clsx(styles.item)}
                onClick={() => {
                  if (item.name === "messages") {
                    dispatch(setMessagesModalIsOpen(true))
                  }
                  if (item.name === "qr") setQrScanModal(true)
                }}
              >
                <span className={clsx(styles.item__icon, item?.bg && styles["item__icon--" + item.bg])}>
                  {item.icon}
                </span>
                <span>
                  <span className={styles.item__txtWrap}>
                    <span>{item?.txtTrans ? t(item.txtTrans) : item?.txt}</span>
                  </span>
                  <span className={styles.item__txtDescr}>
                    {item?.descriptionTrans ? t(item.descriptionTrans) : item.description}
                  </span>
                </span>
                {ordersNoRev && ordersNoRev > 0 ? (
                  <span className={`${styles["item__no-rev"]}`}>{ordersNoRev > 99 ? "99+" : ordersNoRev}</span>
                ) : null}
              </button>
            )
          }
        })}
      </div>
      {isQrScanModal && <QrScanModal open={isQrScanModal} setOpen={setQrScanModal} />}
    </>
  )
}

export default OrdersLine
