import { api } from "./index"
import {
  BaseResponseType,
  ICategory,
  IEmployee,
  IEquipment,
  IOrderReview,
  IOrderWithoutReview,
  IServiceOrderShort,
} from "../../types/content"
import { IServiceOrder, TDirectory } from "../../types/orderTypes"

// @ts-ignore
export const contentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // категории сервисов - Напитки, Уборка, Климат контроль
    // сервисы - Черный чай, Пицца, Минеральная вода
    //Категории(Напитки, Климат контроль) => Подкатегории(Чай, вода, кофе - у категории Напитки, к примеру) => сами уже услуги(черный чай, зелёный чай)

    //создать заявку
    // service_id - required, id услуги
    // count - количество
    // technical_object_id - id объекта, где должна быть оказана услуга. required - если у услуги есть список объектов, иначе игнорируем
    // options - массив выбранных опций
    //    возможные варианты type(не нужно передавать)
    //      text - текстовое поле
    //      number - числовое поле
    //      checkbox - флажок
    //      photo - загрузка фото
    //      attachment - загрузка файлов
    //      boolean - да/нет
    //      datetime - поле даты
    //      list - список вариантов
    //      information - информация
    //  id - id опции
    //  value - введенное значение опции
    //  items - массив id выбранных вариантов из списка, если опция список
    //  objects - массив id выбранных вариантов из справочника
    // images - массив загруженных изображений в формате base64, если опция это загрузка фото
    // files - массив загруженных файлов, если опция это загрузка файлов
    //  filename - имя файла
    //  data - файл в формате base64
    // wasShowed - (bool) была ли опция показана пользователю - есть опции с условиями, которые выводятся только в том случае, если значение у родительской опции принимает какое-то значение
    postCreateOrder: builder.mutation<BaseResponseType<IServiceOrder>, FormData>({
      query: (data) => ({
        url: `public/service-orders/`,
        method: "POST",
        body: data,
      }),
    }),
    //получить список заявок
    /*
      isActive - позволяет вернуть заявки в статусах Завершено и Отменено или наоборот - не возвращать такие
    * */
    postServiceOrders: builder.query<
      BaseResponseType<{
        aItems: IServiceOrderShort[]
        bIsEnd: boolean // для пагинации, последние ли данные
        iCount: number
      }>,
      { limit?: number; offset?: number; isActive: boolean }
    >({
      query: (data) => ({
        url: `public/service-orders/getlist`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Orders"],
    }),
    //получить информацию о заявке
    getServiceOrder: builder.query<IServiceOrder, string>({
      query: (id) => ({
        url: `public/service-orders/${id}`,
      }),
      transformResponse: (response: BaseResponseType<IServiceOrder>) => response.data,
    }),
    //отменить заявку
    postCancelOrder: builder.mutation<any, { id: string; reason: string }>({
      query: (data) => ({
        url: `public/service-orders/cancel/${data.id}`,
        method: "POST",
        body: { reason: data.reason },
      }),
      invalidatesTags: ["Orders"],
    }),
    returnOrderToWork: builder.mutation<BaseResponseType<any[]>, { id: string; reason: string }>({
      query: ({ id, reason }) => ({
        url: `public/service-orders/in-work/${id}`,
        method: "POST",
        body: { reason: reason },
      }),
    }),
    getQuantityOrderWithoutReview: builder.query<{ count: number }, void>({
      query: () => ({
        url: `public/service-orders/count-orders-without-reviews`,
        method: "GET",
      }),
    }),
    // Количество непрочитанных сообщений суммарно (на главной)
    getCountUnreadMessages: builder.query<{ count: number }, void>({
      query: () => ({
        url: `public/service-orders/count-unread-massages`,
        method: "GET",
      }),
    }),
    postOrderModalWasOpened: builder.mutation<any, any>({
      query: (service_order_id) => ({
        url: `public/service-orders/${service_order_id}/modal-review-impression`,
        method: "POST",
      }),
    }),
    getUsers: builder.query<
      { aUsers: IEmployee[]; bIsEnd: boolean; iCount: number },
      { limit?: number; offset?: number; search?: string; department_id: string | null } | void
    >({
      query: (data) => {
        let queryString = ""

        if (data) {
          for (const key in data) {
            if (!queryString) queryString = "?"
            if (queryString && queryString !== "?") queryString = queryString + "&"
            // @ts-ignore
            queryString = queryString + `${key}=${data[key]}`
          }
        }

        return {
          url: `public/user${queryString}`,
          method: "GET",
        }
      },
    }),
    getDirectory: builder.query<
      { aObjects: IEmployee[]; bIsEnd: boolean; iCount: number },
      { type: TDirectory; limit?: number; offset?: number; search?: string }
    >({
      query: (data) => {
        let queryString = ""

        if (data) {
          for (const key in data) {
            if (!queryString) queryString = "?"
            if (queryString && queryString !== "?") queryString = queryString + "&"
            // @ts-ignore
            queryString = queryString + `${key}=${data[key]}`
          }
        }

        return {
          url: `public/service-orders/directory/${data.type}${queryString}`,
          method: "GET",
        }
      },
      transformResponse: (response: BaseResponseType<{ aObjects: IEmployee[]; bIsEnd: boolean; iCount: number }>) =>
        response.data,
    }),
    getIssuedEquipment: builder.query<
      BaseResponseType<{
        aItems: IEquipment[]
        bIsEnd: boolean
        iCount: number
      }>,
      { status: "planed" | "provided" | "returned"; limit?: number; offset?: number }
    >({
      query: (data) => {
        let queryString = ""

        if (data) {
          for (const key in data) {
            if (!queryString) queryString = "?"
            if (queryString && queryString !== "?") queryString = queryString + "&"
            // @ts-ignore
            queryString = queryString + `${key}=${data[key]}`
          }
        }
        return {
          url: `public/technical-object/hold-list/${queryString}`,
          method: "GET",
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  usePostCreateOrderMutation,
  usePostServiceOrdersQuery,
  useLazyPostServiceOrdersQuery,
  useGetServiceOrderQuery,
  useLazyGetServiceOrderQuery,
  usePostCancelOrderMutation,
  useReturnOrderToWorkMutation,
  useLazyGetQuantityOrderWithoutReviewQuery,
  useLazyGetCountUnreadMessagesQuery,
  usePostOrderModalWasOpenedMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetDirectoryQuery,
  useLazyGetIssuedEquipmentQuery,
} = contentApi
