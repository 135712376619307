import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../hooks"
import { updateIsAllowNewServiceCategory } from "../../redux/slice/auth"
import moment from "moment"
import { langWithCurrentMomentCode, servicesBgList } from "../../utils/helpers"
import useWindowSize from "../../hooks/useWindowSize"
import { ICategory } from "../../types/content"
import clsx from "clsx"
import CategoryItem from "../CategoryItem/CategoryItem"
import { getLocale } from "../../utils/getLocale"
import styles from "./ServicesList.module.scss"
import { DEFAULT_LANGUAGE } from "../../utils/constants"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { setSoloServiceIsOpened } from "../../redux/slice/main"

interface IItem extends ICategory {
  bg: string
}

const ServicesList = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const currentLang = getLocale()
  const { object_id } = useParams()

  const { serviceList, serviceListLoading, soloServiceIsOpened } = useAppSelector((state) => state.main)

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
    } else {
      moment.locale(DEFAULT_LANGUAGE)
    }
  }, [currentLang])

  useEffect(() => {
    dispatch(updateIsAllowNewServiceCategory(false))

    // Открытие категории, если в списке она одна
    if (object_id && serviceList?.length === 1 && !soloServiceIsOpened) {
      dispatch(setSoloServiceIsOpened(true))
      navigate(`${location.pathname}/category/${serviceList[0].id}`)
    }
  }, [])

  const { isDesktop } = useWindowSize()

  const list = useMemo(() => {
    if (!serviceList?.length) return null
    let isFive = true
    const result = serviceList.reduce((accumulator: IItem[][], currentValue, index) => {
      if (!isDesktop) {
        if (index % 4 === 0) accumulator.push([])
      } else {
        if (isFive) {
          if (index === 0) accumulator.push([])
          if ((index + 3) % 8 === 0) {
            accumulator.push([])
            if (index !== 0) isFive = false
          }
        } else {
          if (index % 8 === 0) {
            accumulator.push([])
            isFive = true
          }
        }
      }

      accumulator[accumulator.length - 1].push({ ...currentValue, bg: servicesBgList[index % 12] })
      return accumulator
    }, [])

    let sufNum5 = 0
    let sufNum3 = 0
    return result.map((item, index) => {
      const num = index % 2 === 0 ? 5 : 3
      let fullNum = null
      if (isDesktop) {
        if (num === 5) {
          sufNum5 = sufNum5 >= 3 ? 1 : ++sufNum5
        }
        if (num === 3) {
          sufNum3 = sufNum3 >= 3 ? 1 : ++sufNum3
        }
        fullNum = num + "-" + (num === 5 ? sufNum5 : sufNum3)
      }

      let lastClass = null
      if (index + 1 === result.length) {
        lastClass = styles["row--last-" + (isDesktop ? "" : "mob-") + item.length]
      }

      return (
        <div
          key={index}
          className={clsx(
            styles.row,
            !isDesktop && styles.mob,
            !lastClass && fullNum && styles[`row--${fullNum}`],
            lastClass,
          )}
        >
          {item?.map((el) => {
            return (
              <CategoryItem
                key={el.id}
                id={el.id}
                title={el?.name}
                subtitle={el?.description}
                background={el.bg}
                img={el?.image_id}
                className={styles.item}
              />
            )
          })}
        </div>
      )
    })
  }, [serviceList, isDesktop])

  return (
    <div className={styles.serviceList}>
      {isDesktop && (
        <h4
          className={`${styles.serviceList__title} ${
            serviceListLoading ? `selectBack ${styles.serviceList__title_load}` : ""
          }`}
        >
          {t("services")}
        </h4>
      )}

      <div className={styles.serviceList__list}>
        {serviceListLoading && !serviceList.length ? (
          <div className={styles.gridLoading}>
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
            <div className={`selectBack ${styles.elLoading}`} />
          </div>
        ) : (
          <div className={styles.grid}>{list}</div>
        )}
      </div>
    </div>
  )
}

export default ServicesList
