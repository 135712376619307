export const StartFilledIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM5.18851 3.32695L8.95101 5.41723C9.40822 5.67123 9.40822 6.32877 8.95101 6.58277L5.18851 8.67305C4.65529 8.96928 4 8.58371 4 7.97372V4.02628C4 3.41629 4.65529 3.03072 5.18851 3.32695Z"
        fill="#41C061"
      />
    </svg>
  )
}
