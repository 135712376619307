import { Dispatch, FC, SetStateAction, useMemo } from "react"
import { useTranslation } from "react-i18next"
import Button from "../../Assets/Button/Button"
import ModalPortal from "../../Assets/ModalPortal/ModalPortal"
import clsx from "clsx"
import useWindowSize from "../../../hooks/useWindowSize"
import { IEquipment } from "../../../types/content"
import styles from "../Equipments.module.scss"
import moment from "moment"
import { useScrollBlock } from "../../../hooks/useScrollBlock"

interface IRatingModal {
  open: boolean
  setOpen: Dispatch<SetStateAction<IEquipment | undefined>>
  equipment: IEquipment
}

const ModalEquipment: FC<IRatingModal> = ({ open, setOpen, equipment }) => {
  const { allowScroll } = useScrollBlock()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isMobile } = useWindowSize()

  const equipmentType = useMemo(() => {
    if (equipment.finish_date) return "story"
    if (equipment.start_date) return "active"
    return "wait"
  }, [equipment])

  const equipmentTypeText = useMemo(() => {
    if (equipmentType === "active") return t("delivered")
    if (equipmentType === "story") return t("archive")
    if (equipmentType === "wait") return t("awaitingDelivery")
  }, [equipmentType])

  return (
    <ModalPortal
      isOpen={open}
      setIsOpen={() => {
        setOpen(undefined)
      }}
      isCloseBtn={isMobile}
      className={clsx(styles.modal)}
      header={<>{equipment.technical_object.name}</>}
    >
      <div className={styles["modal__content-container"]}>
        <div className={styles["info-container"]}>
          <div className={styles["item"]}>
            <span className={styles["item__name"]}>{t("status")}</span>
            <span className={styles["item__content"]}>{equipmentTypeText}</span>
          </div>
          <div className={styles["item"]}>
            <span className={styles["item__name"]}>{t("inventoryNumber")}</span>
            <span className={styles["item__content"]}>{equipment.technical_object.inventory_number}</span>
          </div>
          {equipment.start_date && (
            <div className={styles["item"]}>
              <span className={styles["item__name"]}>{t("gived")}</span>
              <span className={styles["item__content"]}>{moment.unix(equipment.start_date).format("DD.MM.YYYY")}</span>
            </div>
          )}

          {equipment.finish_date && (
            <div className={styles["item"]}>
              <span className={styles["item__name"]}>{t("taked")}</span>
              <span className={styles["item__content"]}>{moment.unix(equipment.finish_date).format("DD.MM.YYYY")}</span>
            </div>
          )}
        </div>
        <Button
          txt={t("back")}
          mode="gray"
          className={styles.btn}
          onClick={() => {
            allowScroll(true)
            setOpen(undefined)
          }}
        />
      </div>
    </ModalPortal>
  )
}

export default ModalEquipment
