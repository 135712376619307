import clsx from "clsx"
import styles from "../Equipments.module.scss"
import { Dispatch, FC, SetStateAction, useMemo } from "react"
import moment from "moment"
import { StartFilledIcon } from "../../../icons/StartFilledIcon"
import { EndFilledIcon } from "../../../icons/EndFilledIcon"
import { BoxesLight } from "../../../icons/BoxesLight"
import { ArrowCircleTop } from "../../../icons/ArrowCircleTop"
import { ArrowCircleBottom } from "../../../icons/ArrowCircleBottom"
import { IEquipment } from "../../../types/content"
import useWindowSize from "../../../hooks/useWindowSize"

interface Props {
  className?: string
  equipment: IEquipment
  setOpenedEquipment: Dispatch<SetStateAction<IEquipment | undefined>>
}

const Equipment: FC<Props> = ({ className, equipment, setOpenedEquipment }) => {
  const { isMobile } = useWindowSize()
  const equipmentType = useMemo(() => {
    if (equipment.finish_date) return "story"
    if (equipment.start_date) return "active"
    return "wait"
  }, [equipment])

  const statusDates = useMemo(() => {
    return (
      <div className={styles["info__dates"]}>
        {equipment.start_date && (
          <span className={styles["start"]}>
            <StartFilledIcon />
            {moment.unix(equipment.start_date).format("DD.MM.YYYY")}
          </span>
        )}

        {equipment.finish_date && (
          <span className={styles["end"]}>
            <EndFilledIcon />
            {moment.unix(equipment.finish_date).format("DD.MM.YYYY")}
          </span>
        )}
      </div>
    )
  }, [equipment])

  const statusIcon = useMemo(() => {
    switch (equipmentType) {
      case "story":
        return <ArrowCircleBottom />
      case "active":
        return <ArrowCircleTop />
      default:
        return <BoxesLight />
    }
  }, [equipmentType])

  return (
    <div
      className={clsx(className, styles["equipment-item"])}
      onClick={() => {
        if (isMobile) setOpenedEquipment(equipment)
      }}
    >
      <div className={styles["info"]}>
        <h4
          data-tooltip-id={"globalTooltip"}
          data-tooltip-delay-show={300}
          data-tooltip-content={equipment.technical_object.name}
          className={styles["info__name"]}
        >
          {equipment.technical_object.name}
        </h4>
        <p className={styles["info__inventory"]}>{equipment.technical_object.inventory_number}</p>
        {equipmentType !== "wait" && statusDates}
      </div>
      <div className={clsx(styles["icon"], styles[`icon-${equipmentType}`])}>{statusIcon}</div>
    </div>
  )
}

export default Equipment
