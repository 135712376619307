export const BoxesLight = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.76367 6.56975C6.76367 5.62298 7.53118 4.85547 8.47796 4.85547H14.5732C15.52 4.85547 16.2875 5.62298 16.2875 6.56975V11.5221H8.47796C7.53119 11.5221 6.76367 10.7546 6.76367 9.80785V6.56975Z"
        stroke="#F2A60F"
        strokeWidth="1.42857"
      />
      <path
        d="M4.85742 13.2377C4.85742 12.2909 5.62493 11.5234 6.57171 11.5234H12.4765V19.1425H6.57171C5.62493 19.1425 4.85742 18.375 4.85742 17.4282V13.2377Z"
        stroke="#F2A60F"
        strokeWidth="1.42857"
      />
      <path
        d="M12.4766 11.5234H18.3813C19.3281 11.5234 20.0956 12.2909 20.0956 13.2377V17.4282C20.0956 18.375 19.3281 19.1425 18.3813 19.1425H12.4766V11.5234Z"
        stroke="#F2A60F"
        strokeWidth="1.42857"
      />
      <path d="M16.2871 11.5234V14.3806" stroke="#F2A60F" strokeWidth="1.42857" strokeLinecap="round" />
      <path d="M8.66797 11.5234V14.3806" stroke="#F2A60F" strokeWidth="1.42857" strokeLinecap="round" />
      <path d="M11.5254 4.85547V7.71261" stroke="#F2A60F" strokeWidth="1.42857" strokeLinecap="round" />
    </svg>
  )
}
