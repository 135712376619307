export const ArrowCircleTop = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.55984 14.676C5.93967 16.0935 6.77664 17.3461 7.94094 18.2395C9.10523 19.1329 10.5318 19.6172 11.9993 19.6172C13.4669 19.6172 14.8935 19.1329 16.0578 18.2395C17.2221 17.3461 18.059 16.0935 18.4389 14.676"
        stroke="#41C061"
        strokeWidth="1.90476"
      />
      <path
        d="M11.9982 4.37891L11.4033 3.63522L11.9982 3.15926L12.5932 3.63522L11.9982 4.37891ZM12.9506 12.9503C12.9506 13.4763 12.5242 13.9027 11.9982 13.9027C11.4722 13.9027 11.0459 13.4763 11.0459 12.9503L12.9506 12.9503ZM6.64138 7.44475L11.4033 3.63522L12.5932 5.12259L7.83128 8.93212L6.64138 7.44475ZM12.5932 3.63522L17.3551 7.44475L16.1652 8.93211L11.4033 5.12259L12.5932 3.63522ZM12.9506 4.37891L12.9506 12.9503L11.0459 12.9503L11.0459 4.37891L12.9506 4.37891Z"
        fill="#41C061"
      />
    </svg>
  )
}
