import Layout from "../components/Layout/Layout"
import EmployeesLayoutLoader from "../components/LayoutLoaders/EmployeesLayoutLoader"
import Equipments from "../components/Equipment/Equipments"

export default function EquipmentPage() {
  return (
    <Layout mainLoader={<EmployeesLayoutLoader />} isHeaderMobHidden>
      <Equipments />
    </Layout>
  )
}
