export const DesktopLight = () => {
  return (
    <svg
      className="stroke-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.1 6.66732C3.1 5.86468 3.10127 5.32306 3.15567 4.91848C3.20774 4.53116 3.2984 4.36042 3.41242 4.2464C3.52644 4.13239 3.69717 4.04173 4.0845 3.98965C4.48908 3.93526 5.0307 3.93398 5.83333 3.93398H14.1667C14.9693 3.93398 15.5109 3.93526 15.9155 3.98965C16.3028 4.04173 16.4736 4.13239 16.5876 4.2464C16.7016 4.36042 16.7923 4.53116 16.8443 4.91848C16.8987 5.32306 16.9 5.86468 16.9 6.66732V13.5673H3.1V6.66732Z"
        stroke="#4F4F4F"
        strokeWidth="1.2"
      />
      <path
        d="M3.05556 13.566C2.41736 13.566 1.9 14.0834 1.9 14.7216C1.9 15.6666 2.66609 16.4327 3.61111 16.4327H16.3889C17.3339 16.4327 18.1 15.6666 18.1 14.7216C18.1 14.0834 17.5826 13.566 16.9444 13.566H3.05556Z"
        stroke="#4F4F4F"
        strokeWidth="1.2"
      />
    </svg>
  )
}
