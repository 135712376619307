import { useEffect } from "react"
import { BrowserRouter as Router, useRoutes } from "react-router-dom"
import Home from "./pages"
import History from "./pages/history"
import Orders from "./pages/orders"
import Products from "./pages/category/[category_id]/[category_id]"
import { Provider } from "react-redux"
import { store } from "./redux/store"
import "./utils/i18n"
import WithAuth from "./hoc/withAuth"
import RequireAuth from "./hoc/RequireAuth"
import { setDefaultLocale } from "react-datepicker"
import moment from "moment"
import "moment/locale/ru"
// import "moment/locale/fr"
// import "moment/locale/zh-cn"
import "moment/locale/th"
import { getKeyLangMap, langWithCurrentMomentCode } from "./utils/helpers"
import { registerSW } from "./utils/serviceWorkerRegistration"
import Page404 from "./pages/404"
import ReviewsPage from "./pages/reviews"
import EmployeesPage from "./pages/employees"
import ArticlesPage from "./pages/articles/[...id]"
import OrderServicePage from "./pages/category/[category_id]/[service_id]"
import OrderInfoPage from "./pages/order/[id]"
import ObjectPageWrap from "./pages/object/[object_id]"
import { Tooltip } from "react-tooltip"
import CoordinationsPage from "./pages/coordinations"
import MainLayout from "./components/MainLayout/MainLayout"
import ProductsLayout from "./components/ProductsLayout/ProductsLayout"
import {
  DEFAULT_LANGUAGE,
  MAX_AGE_TOKEN_180_DAYS_IN_SECONDS,
  OG_DESCRIPTION,
  OG_TITLE,
  PAGE_IMG,
  PAGE_TITLE,
  STAQ_VERSION_COOKIE,
} from "./utils/constants"
import { useTranslation } from "react-i18next"
import { getCookie, setCookie } from "./utils/cookies"
import { getLocale } from "./utils/getLocale"
import { Helmet } from "react-helmet"
import EquipmentPage from "./pages/equipment"
import ProfilePage from "./pages/profile"

const helmetContext = {}

const RoutesArr = () =>
  useRoutes(
    [
      {
        path: "*",
        element: <Page404 />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: ":locale/",
        element: <Home />,
      },
      {
        path: "/history",
        element: (
          <RequireAuth>
            <History />
          </RequireAuth>
        ),
      },
      {
        path: ":locale/history",
        element: (
          <RequireAuth>
            <History />
          </RequireAuth>
        ),
      },
      {
        path: "/orders",
        element: (
          <RequireAuth>
            <Orders />
          </RequireAuth>
        ),
      },
      {
        path: ":locale/orders",
        element: (
          <RequireAuth>
            <Orders />
          </RequireAuth>
        ),
      },
      {
        path: "/reviews",
        element: (
          <RequireAuth>
            <ReviewsPage />
          </RequireAuth>
        ),
      },
      {
        path: "/coordinations",
        element: (
          <RequireAuth>
            <CoordinationsPage />
          </RequireAuth>
        ),
      },
      {
        path: "/employees",
        element: (
          <RequireAuth>
            <EmployeesPage />
          </RequireAuth>
        ),
      },
      {
        path: "/articles",
        element: (
          <RequireAuth>
            <ArticlesPage />
          </RequireAuth>
        ),
      },
      {
        path: "/articles/:id",
        element: (
          <RequireAuth>
            <ArticlesPage />
          </RequireAuth>
        ),
      },
      {
        path: "/category/:category_id",
        element: <Products />,
      },
      {
        path: ":locale/category/:category_id",
        element: <Products />,
      },
      {
        path: "/category/:category_id/:service_id",
        element: (
          <RequireAuth>
            <OrderServicePage />
          </RequireAuth>
        ),
      },
      {
        path: "/order/:id",
        element: (
          <RequireAuth>
            <OrderInfoPage />
          </RequireAuth>
        ),
      },
      {
        path: "/object/:object_id/",
        element: <ObjectPageWrap />,
        children: [
          {
            index: true,
            element: <MainLayout />,
          },
          {
            path: "category/:category_id",
            element: <ProductsLayout />,
          },
        ],
      },
      {
        path: "/object/:object_id/category/:category_id/:service_id",
        element: (
          <RequireAuth>
            <OrderServicePage />
          </RequireAuth>
        ),
      },
      {
        path: "/equipment",
        element: (
          <RequireAuth>
            <EquipmentPage />
          </RequireAuth>
        ),
      },
      {
        path: "/profile",
        element: (
          <RequireAuth>
            <ProfilePage />
          </RequireAuth>
        ),
      },
    ].map((el) => ({ path: el.path, element: el.element, children: el.children })),
  )

function App() {
  const currentLang = getLocale()
  const { i18n } = useTranslation()
  // const userLang = navigator.language
  // const keyLang = getKeyLangMap(userLang)

  useEffect(() => {
    if (getCookie(STAQ_VERSION_COOKIE) !== String(process.env.REACT_APP_PUBLIC_STAQ_VERSION)) {
      if (typeof window !== "undefined") {
        const allCookies = document.cookie.split(";")
        for (let i = 0; i < allCookies.length; i++) {
          const cookie = allCookies[i]
          const eqPos = cookie.indexOf("=")
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
        }
        sessionStorage.clear()
        localStorage.clear()
        setCookie(STAQ_VERSION_COOKIE, String(process.env.REACT_APP_PUBLIC_STAQ_VERSION), {
          maxAge: MAX_AGE_TOKEN_180_DAYS_IN_SECONDS,
          sameSite: "lax",
        })
      }
    }
  }, [])

  useEffect(() => {
    // вместо DEFAULT_LANGUAGE было keyLang ? keyLang[0] : DEFAULT_LANGUAGE
    const lang =
      !currentLang || currentLang === "dev" || !langWithCurrentMomentCode.get(currentLang)
        ? DEFAULT_LANGUAGE
        : currentLang
    void i18n.init({ lng: lang })
    moment.locale(lang)
    setDefaultLocale(String(currentLang) || DEFAULT_LANGUAGE)
  }, [currentLang])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
        <meta name="description" content={OG_DESCRIPTION} />
        <meta property="og:site_name" content={PAGE_TITLE} />
        <meta property="og:title" content={OG_TITLE} />
        <meta property="og:description" content={OG_DESCRIPTION} />
        <meta property="og:image" content={PAGE_IMG} />
        <link rel="icon" href={`/favicon${process.env.REACT_APP_PUBLIC_PROJECT_TYPE === 'digitask' ? '/digitask' : ''}/favicon.ico`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`/favicon${process.env.REACT_APP_PUBLIC_PROJECT_TYPE === 'digitask' ? '/digitask' : ''}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/favicon${process.env.REACT_APP_PUBLIC_PROJECT_TYPE === 'digitask' ? '/digitask' : ''}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/favicon${process.env.REACT_APP_PUBLIC_PROJECT_TYPE === 'digitask' ? '/digitask' : ''}/favicon-16x16.png`} />
        <link rel="manifest" href={`/favicon${process.env.REACT_APP_PUBLIC_PROJECT_TYPE === 'digitask' ? '/digitask' : ''}/site.webmanifest`} />
        <link rel="mask-icon" href={`/favicon${process.env.REACT_APP_PUBLIC_PROJECT_TYPE === 'digitask' ? '/digitask' : ''}/safari-pinned-tab.svg`} color="#5bbad5" />
        <link rel="apple-touch-icon" href={`/favicon${process.env.REACT_APP_PUBLIC_PROJECT_TYPE === 'digitask' ? '/digitask' : ''}/android-chrome-192x192.png`} />
      </Helmet>
      <Provider store={store}>
        <WithAuth />
        <Router>
          <RoutesArr />
        </Router>
        <Tooltip id="globalTooltip" className={"tooltip-base"} place="top" />
      </Provider>
    </>
  )

  // return (
  //   <HelmetProvider context={helmetContext}>
  //     <Helmet>
  //       <html lang="ru" />
  //       {/*<title>{PAGE_TITLE}</title>*/}
  //       <title>React Helmet Tutorial</title>
  //       <meta name="description" content={OG_DESCRIPTION} />
  //       <meta property="og:site_name" content={PAGE_TITLE} />
  //       <meta property="og:title" content={OG_TITLE} />
  //       <meta property="og:description" content={OG_DESCRIPTION} />
  //       <meta property="og:image" content={PAGE_IMG} />
  //       <body className="dark" />
  //     </Helmet>
  //     <Provider store={store}>
  //       <WithAuth />
  //       <Router>
  //         <RoutesArr />
  //       </Router>
  //       <Tooltip id="globalTooltip" className={"tooltip-base"} place="top" />
  //     </Provider>
  //   </HelmetProvider>
  // )
}

export default App
